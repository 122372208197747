<template>
   <div class="modal fade" ref="mdlEliminacionFamilia" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content" v-show="cantidad_articulos > 0">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 text-center">
              <h1><strong>Eliminación de familia {{familia_articulo}}</strong></h1>
            </div>
          </div>
        </div>

        <div class="p-small text-center bg-light">
          <span style="font-size:20px" v-show="eliminar_familia_cambio_familia==false">
            <i class="mdi mdi-alert color-yellow"></i>
            La familia <strong>{{familia.familia}}</strong> contiene <strong>{{cantidad_articulos}}</strong> artículos relacionados
          </span>
          <span style="font-size:20px" v-show="eliminar_familia_cambio_familia!=false">
            <i class="mdi mdi-check-bold color-primary-info"></i>
            Se ha eliminado la familia <strong>{{familia.familia}}</strong>
          </span>
        </div>

        <div class="modal-body">
          <div class="row" v-show="eliminar_familia == false && eliminar_familia_cambio_familia==false">
            <div class="col-md-8 offset-md-2 text-center">
              <h4>
                <strong>
                  Al eliminar una familia con artículos relacionados dichos
                  artículos quedarán sin familia y se deberá actualizar
                  manualmente en cada artículo.
                </strong>
              </h4>
            </div>
          </div>

          <div class="row justify-content-center" v-show="eliminar_familia_cambio_familia!=false">
            <div class="col-md-12 text-center">
              <h4>
                <strong>
                  Todos los artículos de la anterior familia <strong>{{familia.familia}}</strong> fueron movidos a la familia <strong>{{familia_seleccionada.familia}}</strong> ¡exitosamente!
                </strong>
                <br />
              </h4>
            </div>
          </div>
          
          <div class="row justify-content-center" v-show="eliminar_familia == true">
            <i class="mdi mdi-check-bold color-primary text-center"></i>
            <div class="col-md-12 text-center">
              <h3>La familia <strong>{{familia.familia}}</strong> ha sido eliminada correctamente</h3>
            </div>
          </div>
          
          <br>

          <div class="row" v-show="no_mover_articulos == false && mover_articulos == false">
            <div class="col-md-12 text-center">
              <h3>¿Quieres mover los artículos a otra familia?</h3>
            </div>
          </div>

          <div class="row justify-content-center" v-show="mover_articulos != false && eliminar_familia_cambio_familia==false">
            <div class="col-6">
              <h3>Selecciona la nueva familia</h3>
              <select
                v-model="id_familia_seleccionada"
                class="form-control"
                @change="familiaSeleccionada(),
                  id_familia_seleccionada==null? familia_seleccionada={}:''
                "
              >
                <option :value="null">Seleccionar</option>
                <option v-show="idfam != familia.id" v-for="familia in familias" :key="familia.id" :value="familia.id">{{familia.division}}</option>
              </select>
            </div>
          </div>
          <div class="row" v-show="mover_articulos != false">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th style="width:150px">Nueva familia</th>
                      <th>división</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{familia_seleccionada.familia}}
                      </td>
                      <td>
                        {{familia_seleccionada.division}}
                      </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row" v-show="no_mover_articulos == false && mover_articulos == false">
            <div class="col-md-6 text-right">
              <button class="btn btn-outline btn-primary" @click="moverArticulo()">Sí</button>
            </div>
            <div class="col-md-6 text-left">
              <button class="btn btn-outline btn-warning" @click="noMoverArticulo()">No</button>
            </div>
          </div>

          <div class="row" v-show="no_mover_articulos != false && eliminar_familia == false">
            <div class="col-md-12 col-md-offset-3 text-center">
              <i class="mdi mdi-alert color-red"></i>
              <h3 class="color-red-info">Esta acción es irreversible para continuar de clic en eliminar.</h3>
              <br />
            </div>
          </div>

          <div class="row text-center" v-show="no_mover_articulos != false && eliminar_familia == false || mover_articulos != false && eliminar_familia_cambio_familia==false">
            <br>
            <button v-show="mover_articulos == false" class="btn btn-outline btn-danger" @click="eliminarFamilia()">
              <i class="mdi mdi-check-bold"></i> Eliminar
            </button>&nbsp;
            <button v-show="mover_articulos != false" class="btn btn-success btn-rounded waves-effect waves-light" @click="eliminarFamiliaCambioFamilia()" :disabled="id_familia_seleccionada == null">
              <i class="mdi mdi-refresh"></i> Cambiar familia y eliminar anterior
            </button>&nbsp;
            <button type="button" class="btn btn-outline btn-default" data-bs-dismiss="modal" @click="reset()">
              <i class="mdi mdi-close-thick"></i>
              Cancelar
            </button>
          </div>

          <div class="row" v-show="eliminar_familia == true || eliminar_familia_cambio_familia!=false">
            <div class="col-md-12 text-center">
              <button type="button" class="btn btn-outline btn-primary" data-bs-dismiss="modal" @click="reset()">
                <i class="mdi mdi-check-bold"></i>
                OK
              </button>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-content" v-show="cantidad_articulos <= 0">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 text-center">
              <h1><strong>Eliminación de familia {{familia_articulo}}</strong></h1>
            </div>
          </div>
        </div>

        <div class="modal-body">
          <div class="row justify-content-center" v-show="eliminar_familia == true">
            <div class="col-md-12 text-center">
              <i class="mdi mdi-check-bold color-primary"></i>
              <h3>La familia <strong>{{familia.familia}}</strong> ha sido eliminada correctamente</h3>
            </div>
          </div>

          <div class="row justify-content-center" v-show="no_mover_articulos == false && eliminar_familia == false">
            <div class="col-md-12 text-center">
              <i class="mdi mdi-alert color-red"></i>
              <br>
              <h3 class="color-red-info">Esta acción es irreversible para continuar de clic en eliminar.</h3>
            </div>
          </div>

          <div class="row text-center" v-show="no_mover_articulos == false && eliminar_familia == false || mover_articulos != false && eliminar_familia_cambio_familia==false">
            <br>
            <button v-show="mover_articulos == false" class="btn btn-outline btn-danger" @click="eliminarFamilia()">
              <i class="mdi mdi-check-bold"></i> Eliminar
            </button>&nbsp;
            <button type="button" class="btn btn-outline btn-default" data-bs-dismiss="modal" @click="reset()">
              <i class="mdi mdi-close-thick"></i>
              Cancelar
            </button>
          </div>

          <div class="row" v-show="eliminar_familia == true || eliminar_familia_cambio_familia!=false">
            <div class="col-md-12 text-center">
              <button type="button" class="btn btn-outline btn-primary" data-bs-dismiss="modal" @click="reset()">
                <i class="mdi mdi-check-bold"></i>
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'
import FamiliaArticulosSrv from '@/services/inventario/FamiliaArticulosSrv.js'

export default {
  name:'mdlEliminacionFamilia',
  data: function() {
    return {
      familia: {},
      familia_articulo: '',
      no_mover_articulos : false,
      mover_articulos : false,
      eliminar_familia: false,
      eliminar_familia_cambio_familia:false,
      idfam: null,

      id_familia_seleccionada: null,
      
      cantidad_articulos: 0,

      familias:[],
      familia_seleccionada:{}
    }
  },
  methods: {
    eliminarFamilia(){
      var self = this

      self.eliminar_familia= true
      
      let idFamilia = self.familia.id

      FamiliaArticulosSrv.eliminar(idFamilia).then(response => {
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo eliminar la familia '+self.familia.division
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    mostrar(familia) {
      var self = this
      this.familia = familia;
      self.familia_articulo = familia.division
      self.idfam = familia.id
      self.cantidad_articulos = familia.cantidad_articulos
      // $(this.$refs.mdlEliminacionFamilia).modal("show")
      var modal = new Modal(this.$refs.mdlEliminacionFamilia)
      modal.show()
    },

    noMoverArticulo(){
      this.no_mover_articulos = true
    },

    eliminarFamiliaCambioFamilia(){
      this.eliminar_familia_cambio_familia = true
      
      let idDestino= this.familia_seleccionada.id
      this.migrarFamilia(idDestino)
    },

    moverArticulo(){
      this.mover_articulos = true
      this.refrescarFamilias()
    },

    familiaSeleccionada(){
      let self = this

      let idFamilia = self.id_familia_seleccionada

      FamiliaArticulosSrv.familiaJSON(idFamilia).then(response => {
        self.familia_seleccionada = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la familia de artículos' + idFamilia
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    migrarFamilia(idDestino){
      let self = this
      let idFamilia = self.familia.id
      
      FamiliaArticulosSrv.migrarArticulos(idFamilia, idDestino).then(response => {
        self.eliminarFamilia()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo migrar los artículos a la otra familia '+self.familia_seleccionada.division
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    
    refrescarFamilias: function() {
      var self = this

      let params = {
        sin_paginacion: true
      }

      FamiliaArticulosSrv.familiasJSON(params).then(response => {
        let page = response.data
        self.familias = page
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las familias de artículos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    reset(){
      var self = this

      self.no_mover_articulos = false
      self.eliminar_familia = false
      self.mover_articulos = false
      self.eliminar_familia_cambio_familia = false
      self.familia= {}
      self.familia_articulo = ''
      self.id_familia_seleccionada= null
      self.cantidad_articulos= 0
      self.familias=[]
      self.familia_seleccionada= {}
      self.idfam = null
      
      self.$emit('familia:eliminada',true)
    }
  }
}
</script>

<style scoped>
.color-yellow {
  color: #F8AC59;
  font-size: 25px;
}
.color-primary {
    color: #1ab394;
    font-size: 150px;
}

.color-primary-info {
    color: #1ab394;
}

.color-red {
    color: #ed5565;
    font-size: 150px;
}

.color-red-info {
    color: #ed5565;
}
</style>