<template>
  <Layout :tituloPagina="'Inventario | Familias de artículos'">
    <div class="row">
      <div class="offset-lg-2 col-lg-6 col-9 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarFamilia()
          "
          :datoini="dato"
          placeholder="Divisió y/o Descripción"
        ></filtrador>
      </div>
      <div class="col-md-4 col-3">
        <router-link
          :to="{name: 'nuevaFamiliaArticulos' }" 
          class="btn btn-success bx-pull-right"
        >
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Agregar nueva familia
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>
    <br />
    <div class="card">
      <h5 class="card-header">Familias</h5>
      <div class="card-body" style="min-height: 475px;">
      <div class="table-responsive">
        <table id="tblListaFamilias" class="table table-hover table-nowrap">
          <thead>
            <tr>
              <th style="min-width: 100px">Familia de artículos</th>
              <th style="min-width: 200px">División</th>
              <th style="min-width: 150px">Cantidad de artículos</th>
              <th style="min-width: 600px">Descripción</th>
              <th style="min-width: 150px">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="familia in familias" :key="familia.id">
              <td class="fw-semibold">
                {{familia.familia}}
              </td>
              <td>
                <router-link
                  :to="{ name: 'edicionFamiliaArticulos', params: { id: familia.id } }"
                >
                  {{familia.division}}
                </router-link>
                
              </td>
              <td class="fw-semibold">
                {{familia.cantidad_articulos}}
              </td>
              <td>
                {{familia.descripcion}}
              </td>
              <td>
                <router-link
                  :to="{ name: 'edicionFamiliaArticulos', params: { id: familia.id } }"
                  class="btnEditar btn btn-warning btn-sm"
                >
                  <i class="fas fa-edit"></i>
                  Editar
                </router-link>
                <button
                  class="btnBorrar btn btn-danger btn-sm"
                  @click="preguntaEliminar(familia)"
                >
                  <i class="fas fa-trash"></i>
                  Eliminar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="text-center">
    <paginador
      :pag="page"
      :totpag="totpag"
      :epp="epp"
      @cargar-pagina="cargarPagina"
      @epp-actualizado="
        epp = $event,
        refrescarFamilia()
      "
    ></paginador>
    </div>

    <mdl-eliminacion-familia 
      ref="mdlEliminar"
      @familia:eliminada="familiaEliminada($event)"
    ></mdl-eliminacion-familia>
    <br>
  </Layout>
</template>
  
<script>
import Layout from "@/views/layouts/main.vue"
import Filtrador from '@/components/Filtrador.vue'
import Paginador from '@/components/Paginador.vue'
import MdlEliminacionFamilia from './MdlEliminacionFamilia.vue'
import FamiliaArticulosSrv from '@/services/inventario/FamiliaArticulosSrv.js'
import { todoGetters } from "@/state/helpers";

export default {
  components: {
    Layout,
    MdlEliminacionFamilia,
    Filtrador,
    Paginador,
  },
  data() {
    return {
      dato: '',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.listadoFamiliaArticulos.por_pagina') || 10),
      tc: 0, // Total de clientes
      totpag: 0, // Total de páginas
      familias:[],
    }
  },
  created() {
    var self = this

    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.page) this.page = parseInt(this.$route.query.page)
    if (this.$route.query.epp) this.epp = parseInt(this.$route.query.epp)
        
    self.refrescarFamilia()
  },
  computed:{
    ...todoGetters
  },
  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarFamilia({ page: n })
    },
    familiaEliminada(eliminada){
      this.refrescarFamilia()
    },
    preguntaEliminar: function(familia) {
      var self = this
      if(!self.tienePermiso('inventario.familiasArticulos.eliminar','accion')){
        iu.msg.warning(
        `No tienes permiso para acceder a <strong style="color: #e65853">inventario.familiasArticulos.eliminar</strong>,
        revisa los permisos de rol con tu administrador de sistema`)
        
        return
      }
      this.$refs.mdlEliminar.mostrar(familia)
    },
    refrescarFamilia: function() {
      var self = this

      iu.spinner.mostrar('#tblListaFamilias')

      this.$router.replace({
        query: {
          dato: self.dato,
          page: self.page,
          epp: self.epp
        }
      }).catch(error => {})

      let params = {
        page: self.page,
        epp: self.epp,
        dato: self.dato,
        con_cantidad_articulos: true,
        orden:'asc'
      }

      FamiliaArticulosSrv.familiasJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoFamiliaArticulos.por_pagina', self.epp)
        let page = response.data
        self.familias = page.data
        self.tc = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarFamilia()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las familias de artículos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tblListaFamilias')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tblListaFamilias')
  }
}
</script>